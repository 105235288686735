import React from 'react'

import styles from './container.module.css'

const Container = ({children, fullSize}) => {
  const content = fullSize ? <div className={styles.rootFullSize}>{children}</div> : <div className={styles.rootMaxWidth}>{children}</div>
  return content
}

export default Container
