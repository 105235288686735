import {graphql, StaticQuery} from 'gatsby'
import React, { useState, useEffect, useRef } from 'react'

import Layout from '../components/layout'

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
    }
  }
`

function useOutsideAlerter(menuRef, hamburgerRef, setShowNav) {
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  })

  function handleClickOutside(event) {
    if (menuRef.current && !menuRef.current.contains(event.target) && hamburgerRef.current && !hamburgerRef.current.contains(event.target)) {
      setShowNav(false)
    }
  }
}

function LayoutContainer (props) {
  const [showNav, setShowNav] = useState(false)
  const menuRef = useRef(null);
  const hamburgerRef = useRef(null);
  useOutsideAlerter(menuRef, hamburgerRef, setShowNav);

  function handleShowNav () {
    setShowNav(true)
  }
  function handleHideNav () {
    setShowNav(false)
  }
  return (
    <StaticQuery
      query={query}
      render={data => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add "Site settings" data'
          )
        }
        return (
          <React.Fragment>
          <Layout
            {...props}
            showNav={showNav}
            siteTitle={data.site.title}
            handleHideNav={handleHideNav}
            handleShowNav={handleShowNav}
            menuRef={menuRef}
            hamburgerRef={hamburgerRef}
          />
          </React.Fragment>
        )
      }}
    />
  )
}

export default LayoutContainer
