import {Link} from 'gatsby'
import React from 'react'
import ProjectLink from './project-link'
import {cn} from '../lib/helpers'

import styles from './project-preview-grid.module.css'

const generateLink = (previewCategory, node, props) => (
  <li key={node.id} onMouseEnter={(e) => props.handleHoverOverLink(e, previewCategory, node)} onMouseLeave={(e) => props.handleLeaveLink(e)}>
    <ProjectLink {...node} pageType={props.pageType} />
  </li>
)

function ProjectPreviewGrid (props) {
  return (
    <div className={(props.pageType === 'FEEL') ? cn(styles.root, styles.rootFeel): cn(styles.root, styles.rootThink)}>
      <div>
        {/* To align properly the text, I should calculate the height of the biggest container and give the same height to the other one. UPGRADE */}
        {props.title && <h2 className={styles.title} dangerouslySetInnerHTML={{__html: props.title}} />}
        {props.subtitle && props.pageType && <p className={styles.subtitleBox}><span className={(props.pageType === 'FEEL') ? styles.subtitleFeel : styles.subtitleThink}>{props.subtitle}</span></p>}
        <ul className={styles.linkList}>
          {props.nodes &&
            props.nodes.map(node => {
              let objCategory = node.categories && node.categories.find(category => category.title === 'Home-think' || category.title === 'Home-feel' )
              if (objCategory && objCategory.title === 'Home-think' && props.pageType === 'THINK') {
                return generateLink("think", node, props)
              } else if (objCategory && objCategory.title === 'Home-feel' && props.pageType === 'FEEL') {
                return generateLink("feel", node, props)
              }
          })}
        </ul>
      </div>
    </div>
  )
}

ProjectPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default ProjectPreviewGrid
