import React from 'react'
import Header from './header'

import '../styles/layout.css'
import styles from './layout.module.css'

const Layout = ({children, handleHideNav, handleShowNav, showNav, siteTitle, menuRef, hamburgerRef}) => (
  <>
    <Header siteTitle={siteTitle} handleHideNav={handleHideNav} handleShowNav={handleShowNav} showNav={showNav} hamburgerRef={hamburgerRef} menuRef={menuRef}/>
    <div className={styles.content}>{children}</div>
  </>
)

export default Layout
