import React from 'react'
import styles from './image.module.css'
import {cn} from '../lib/helpers'

import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

export function DynamicImage({styleImg, imageContainer, imageOptimizationOn}) {
  const imageServedBySanity = imageUrlFor(buildImageObj(imageContainer)).url()
  return (
    (typeof imageOptimizationOn !== 'undefined') && (
      <img
      style={styleImg}
      data-piio={imageOptimizationOn ? imageServedBySanity : null}
      src={
        imageOptimizationOn ?
        "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        : imageServedBySanity
      }
      alt={imageContainer.alt}
    />
    )
  )
}

export function StaticImage({styleImg, imageContainer, alt, imageOptimizationOn}) {
  return (
    <img
      style={styleImg}
      data-piio={imageOptimizationOn ? imageContainer : null}
      src={
        imageOptimizationOn ?
        "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        : imageContainer
      }
      alt={alt}
    />
  )
}

export function VerticalHomepageImageContainer ({imageContainer, children}) {
  return (
    <div className={cn(styles.verticalImageOuterContainer, styles.previewImage)}>
      <div className={styles.verticalImageInnerContainer}>
        {imageContainer && imageContainer.asset && (
          children
        )}
      </div>
    </div>
  )
}
