import React from 'react'

const ArrowDown = ({ href, styles }) => {
  return (
    <a href={href} className={styles}>
      <svg width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 35.5L20 10L40 10L20 35.5Z" fill="#092103"/>
        <path d="M20 35.5L20 10L0 10L20 35.5Z" fill="#58FFEB"/>
        <rect x="10" width="10" height="10" fill="#58FFEB"/>
        <rect x="20" width="10" height="10" fill="#092103"/>
      </svg>
    </a>
  )
}

export default ArrowDown
