import React from 'react'
import {Link} from 'gatsby'

import styles from './navbar.module.css'
import {cn} from '../lib/helpers'

const Navbar = ({showNav}) => (
  <nav className={styles.nav}>
    <ul className={showNav ? styles.mobileNavOpen : styles.navClosed}>
      <li className={showNav ? cn(styles.navLink, styles.navLinkMobileNavOpen) : cn(styles.navLink, styles.navLinkMobileNavClosed)}><Link to='/#section-2'>Work</Link></li>
      <li className={showNav ? cn(styles.navLink, styles.navLinkMobileNavOpen) : cn(styles.navLink, styles.navLinkMobileNavClosed)}><Link to='/#section-3'>About</Link></li>
      <li className={showNav ? cn(styles.navLink, styles.navLinkMobileNavOpen) : cn(styles.navLink, styles.navLinkMobileNavClosed)}><Link to='/#section-4'>Clients</Link></li>
      <li className={showNav ? cn(styles.navLink, styles.navLinkMobileNavOpen) : cn(styles.navLink, styles.navLinkMobileNavClosed)}><Link to='/#section-5'>Contact</Link></li>
    </ul>
  </nav>
)

export default Navbar
