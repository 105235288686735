import React from 'react'

const LockIcon = () => (
  <svg
    viewBox="0 0 585.354 585.354"
    fill='#58FFEB'
    xmlns='http://www.w3.org/2000/svg'
    preserveAspectRatio='xMidYMid'
    width='1em'
    height='1em'
  >
    <g>
      <g>
        <path d="M292.679,0C198.29,0,121.496,76.791,121.496,171.183v97.767c0,1.111-1.371,2.983-2.448,3.341
          c-9.257,3.087-16.775,5.762-23.657,8.415c-8.207,3.164-14.397,12.259-14.397,21.157v224.641c0,8.837,6.15,17.94,14.305,21.172
          c63.097,25.003,129.505,37.678,197.379,37.678s134.282-12.678,197.382-37.681c8.152-3.231,14.299-12.332,14.299-21.169V301.863
          c0-8.898-6.189-17.993-14.4-21.16c-6.885-2.653-14.402-5.328-23.652-8.415c-1.074-0.358-2.445-2.231-2.445-3.342V171.18
          C463.857,76.791,387.068,0,292.679,0z M246.053,370.281c0-25.769,20.875-46.622,46.623-46.622
          c25.746,0,46.621,20.851,46.621,46.622c0,17.075-9.629,31.371-23.311,39.475v77.081c0,12.886-10.426,23.311-23.311,23.311
          c-12.886,0-23.311-10.425-23.311-23.311v-77.081C255.683,401.652,246.053,387.356,246.053,370.281z M405,171.18v84.355
          c-36.834-7.926-74.623-11.94-112.306-11.943c-37.666,0-75.447,4.015-112.338,11.934V171.18c0-61.935,50.386-112.32,112.32-112.32
          C354.609,58.859,405,109.245,405,171.18z"/>
      </g>
    </g>
  </svg>
)

export default LockIcon

