import React, { useState, useEffect } from 'react'
import {graphql} from 'gatsby'
import './reset.style.css'
import styles from './index.module.css'

import {cn} from '../lib/helpers'

import { StaticImage, DynamicImage, VerticalHomepageImageContainer } from '../components/image'

import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import ProjectPreviewGrid from '../components/project-preview-grid'
import ArrowDown from '../components/arrow-down'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { piioData } from '../../../PASSWORDS'

import brandImage from '../../images/Brands-logos_noMargins.png'

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
      subtitle
      description
      keywords
      projectsPassword
    }
    projects: allSanitySampleProject(
      sort: {fields: [publishedAt], order: DESC}
      filter: {slug: {current: {ne: null}}, publishedAt: {ne: null}}
    ) {
      edges {
        node {
          id
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
          categories {
            _id
            title
          }
          title
          homepageName
          passwordProtected
          slug {
            current
          }
        }
      }
    }
  }
`

function IndexPage (props) {

  useEffect(() => {
    const script = document.createElement('script');

    script.src = `//js.piio.co/${piioData.appKey}/piio.min.js`;
    script.type = "application/javascript"

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  const {data, errors} = props

  const [thinkIsVisible, setThinkVisibility] = useState(true);
  const [feelIsVisible, setFeelVisibility] = useState(true);
  const [hoveredProject, setHoveredProject] = useState(null);

  const handleHoverOverLink = (e, visibleSide, hoveredProject) => {
    e.preventDefault()
    setHoveredProject(hoveredProject)
    if (visibleSide === "think") {
      setThinkVisibility(true)
      setFeelVisibility(false)
    } else if (visibleSide === "feel") {
      setFeelVisibility(true)
      setThinkVisibility(false)
    }
  }

  const handleLeaveLink = (e) => {
    e.preventDefault()
    setThinkVisibility(true)
    setFeelVisibility(true)
  }


  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const projectNodes = (data || {}).projects
    ? mapEdgesToNodes(data.projects)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Container fullSize>
        <div className={styles.grid}>
          <section className={styles.sectionGrid} id="section-1">
            <h1 className={styles.headingH1}>Hi. I'm Laura Pedroni.</h1>
              <p className={styles.homeParagraph}>
                <span className={styles.textHighlight}>Senior/Lead UX Designer</span> with 10+ years experience working on projects <br className={styles.lineJump} />
                across <span className={styles.textHighlight}>tangible</span> and <span className={styles.textHighlight}>digital</span> spaces.
              </p>
            <div>
              <p className={styles.textSecondary}>
                <span>I advise on business strategy and product roadmap, design wireframes, design and run user tests and interviews, and work with developers down to the last detail for production.</span>
              </p>
            </div>
            <ArrowDown href="#section-2" styles={cn(styles.toNextSection, styles.arrowSection)}/>
          </section>
        </div>

        <div className={styles.wideGrid}>
            <section className={styles.sectionWideGrid} id="section-2">
              <div className={styles.section2BodyContainer}>
                <div className={thinkIsVisible ? null : styles.hiddenMediumScreensAndAbove}>
                  {projectNodes && (
                    <ProjectPreviewGrid
                      handleHoverOverLink={handleHoverOverLink}
                      handleLeaveLink={handleLeaveLink}
                      pageType='THINK'
                      title={"See<br/>how I<br/> think"}
                      subtitle="Projects primarily led by strategic thinking, user research and a rigorous process."
                      nodes={projectNodes}
                    />
                  )}
                </div>

                {hoveredProject &&
                  <VerticalHomepageImageContainer imageContainer={hoveredProject.mainImage}>
                    <DynamicImage
                      imageOptimizationOn={false}
                      imageContainer={hoveredProject.mainImage}
                    />
                  </VerticalHomepageImageContainer>
                }

                <div className={feelIsVisible ? null : styles.hiddenMediumScreensAndAbove}>
                  {projectNodes && (
                    <ProjectPreviewGrid
                      handleHoverOverLink={handleHoverOverLink}
                      handleLeaveLink={handleLeaveLink}
                      pageType='FEEL'
                      title={"See<br/>how I<br/> feel"}
                      subtitle="Projects primarily led by an artistic vision, anchored in the material processes that brought them to life."
                      nodes={projectNodes}
                    />
                  )}
                </div>
              </div>

              <ArrowDown href="#section-3" styles={cn(styles.toNextSection__fullWidthSection, styles.arrowSectionSpecial)}/>
            </section>
          </div>


        <div className={styles.grid}>
          <section className={styles.sectionGrid} id="section-3">
            <h2 className={styles.headingH21}>About</h2>
            <p className={styles.homeParagraph}><span className={styles.textHighlight}>I have worked in tech for 10+ years.</span>
              <span> In a past life, I ran a </span>
              <span className={styles.textHighlight}>company creating bespoke interactive installations</span><span> for advertising agencies, worldwide brands and London based design studios. I don't like spreadsheets and paperwork so much, so I dropped the CEO hat to </span>
              <span className={styles.textHighlight}>focus on concept, user experience and prototyping</span> for <span className={styles.textHighlight}>digital and tangible projects</span>
              <span>. I also spent a year and a half solely working on production ready React dev projects to make sure I was </span><span className={styles.textHighlight}>fluent in code</span>.
            </p>

            <ArrowDown href="#section-4" styles={cn(styles.toNextSection, styles.arrowSection)}/>
          </section>

          <section className={styles.sectionGrid} id="section-4">
              <h2 className={styles.headingH21}>Clients</h2>
              <StaticImage
                imageOptimizationOn={false}
                styleImg={{width: "100%"}}
                imageContainer={brandImage}
                alt={"Clients: Nike, Ray-Ban, Ubisoft, RBS, Sony, Autodesk, Fjord, Stinkdigital, Wonderland, Bacardi, Seeper, R/GA, BETC, Secret Cinema, Artangel, Waag Society"}
              />
              <ArrowDown href="#section-5" styles={cn(styles.toNextSection, styles.arrowSection)}/>
            </section>

          <section className={styles.sectionGrid} id="section-5">
            <div className={styles.section5Body}>
              <div>
                <h2 className={styles.headingH21}>Let's work together</h2>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <p className={styles.homeParagraph}>I'm always happy to chat about <span className={styles.textHighlight}>interesting ideas</span><br className={styles.lineJump}/> and <span className={styles.textHighlight}>new collaborations</span>.
                  </p>
                  <a className={styles.mail} href="mailto:hello@laurapedroni.com">hello@laurapedroni.com</a>
                </div>
              </div>
              <div className={styles.section5Detail}>
                <h3 className={styles.headingH3}>Bring me in for</h3>
                <ul className={styles.textDetail}>
                  <div>
                    <li>Pitchwork or projects</li>
                    <li>Ideas or concept development</li>
                  </div>
                  <div>
                    <li>Strategy - with user at the center</li>
                    <li>UX (digital or tangible)</li>
                  </div>
                  <div>
                    <li>Front-End prototyping in code</li>
                    <li><span>Front-End production ready code</span> <br/> <span style={{wordWrap: "break-word", whiteSpace: "normal"}}>(I prefer coding with HTML / CSS / Javascript / React)</span></li>
                  </div>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </Container>
    </Layout>
  )
}

export default IndexPage


// If I wanted to use the backend to create my front-end, I would have used a structure like this, linked to the studio
// <Container>
//   <h1>Welcome to {site.title}</h1>
//   <h2>{site.subtitle}</h2>
  // {projectNodes && (
  //   <ProjectPreviewGrid
  //     title='Latest projects'
  //     nodes={projectNodes}
  //     browseMoreHref='/archive/'
  //   />
  // )}
// </Container>
