import {Link} from 'gatsby'
import React from 'react'
import Icon from './icon'
import {cn} from '../lib/helpers'
import Navbar from '../components/navbar'

import styles from './header.module.css'

function Header ({menuRef, hamburgerRef, handleHideNav, handleShowNav, showNav, siteTitle}) {

  return (
    <div className={styles.root}>
      <div className={styles.wrapperContainer}>
        <div className={cn(styles.wrapper, styles.sticky)}>
          <div className={styles.branding}>
            <Link to='/'>{siteTitle}</Link>
          </div>
          <button
            ref={hamburgerRef}
            className={cn(styles.toggleNavButton, showNav ? styles.openNav : styles.closedNav)}
            onClick={showNav ? handleHideNav : handleShowNav}
            >
            <Icon symbol='hamburger' />
          </button>
        </div>
        <div ref={menuRef} onClick={showNav ? handleHideNav : handleShowNav}>
          <Navbar showNav={showNav}/>
        </div>
      </div>
    </div>
  )
}

export default Header
