import {Link} from 'gatsby'
import React from 'react'
import {cn} from '../lib/helpers'
import Icon from './icon'

import styles from './project-preview.module.css'

function ProjectLink (props) {
  return (
    <Link className={(props.pageType === 'FEEL') ? cn(styles.root, styles.rootFeel): cn(styles.root, styles.rootThink)} to={`/project/${props.slug.current}`}>
      { props.homepageName ?
        <p className={cn(styles.title)}>{props.homepageName}</p>
        : <p className={cn(styles.title)}>{props.title}</p>
      }
      { props.passwordProtected ?
        <Icon symbol='lock' />
        : null
      }
    </Link>
  )
}

export default ProjectLink
